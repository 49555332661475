import { InternalDocumentsService } from '@/modules/internal-documents/internal-documents-service';
import Errors from '@/shared/error/errors';

// action types
export const DO_FETCH = 'doFetch';
export const SET_FILTER = 'doSetFilter';
export const DO_SELECT = 'doSelect';

// mutation types
export const FETCH_SUCCESS = 'fetchSuccess';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';
export const UPDATE_RECORD = 'updateRecord';
export const SET_SORT = 'setSort';
export const SET_SELECTED_ROWS = 'setSelectedRows';

const state = {
    rows: [],
    total: 0,
    storeFilters: {},
    currentPage: 1,
    storeSort: { sortBy: '', sortDesc: false },
    selectedRows: [],
};

const getters = {
    rows: (state) => state.rows || [],
    total: (state) => state.total,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
    sorting: (state) => state.storeSort,
    selectedRows: (state) => state.selectedRows,
};

const actions = {
    [DO_FETCH](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'internalDocuments/get', { root: true });

            payload.query = 'is_internal eq true'; //fetch internal documents.

            let filters = { params: { ...payload, pagination: true } };
            if (payload && !payload?.sort) filters = {
                params: {
                    ...payload,
                    sort: '-created_date'
                }
            };

            InternalDocumentsService.list(filters)
                .then(({ data }) => {
                    context.commit(FETCH_SUCCESS, {
                        rows: data.data,
                        total: data?.pagination?.total,
                    });
                    resolve();
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'internalDocuments/get', { root: true });
                });
        });
    },
    [SET_FILTER](context, { key, value }) {
        context.commit(SET_FILTERS, { key, value })
    },
    [DO_SELECT](context, payload) {
        context.commit(SET_SELECTED_ROWS, payload);
    },
};

const mutations = {
    [FETCH_SUCCESS](state, payload) {
        state.rows = payload.rows;
        state.total = payload.total;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    },
    [SET_SORT](state, value) {
        state.storeSort = value;
    },
    [SET_SELECTED_ROWS](state, payload) {
        state.selectedRows = payload;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
